// export const url = '' /*'http://192.168.0.10:7777'*/
// export const url = ''
// export const url = 'http://127.0.0.1:8000'
export const url = 'https://polygraf.net'
// export const url = 'http://uat.oleksii.space'
// export const url = 'https://polygraf.ubaly.co'
export const client_id = '1_23m8izxsmnr440gscs8cgk0g8s4ogck08g0k44s4og4sgo4cok'
export const client_secret = '3snks98pcy2osw4kooc0sc4scwwwkggow0c0sks8kccg0swokc'
export const grant_type_pass = 'password'
export const grant_type_refresh = 'refresh_token'

/* Константы для работы с пользователями */
export const GET_ALL_USER = 'GET_ALL_USER'
export const REMOVE_USER = 'REMOVE_USER'


/* Константы для работы с поиском */
export const IS_SEARCH = 'IS_SEARCH'
export const HANDLE_SEARCH = 'HANDLE_SEARCH'
export const VALUE_SEARCH = 'VALUE_SEARCH'

/*Константы для работы с активити логом */
export const GET_ALL_ACTIVITIES = 'GET_ALL_ACTIVITIES'

/*Константы для ролей*/

export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const CHANGE_ROLE_NAME = 'CHANGE_ROLE_NAME'

/*Константы для категорий*/

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

/*Константы для новостей */
export const GET_ALL_ARTICLE = 'GET_ALL_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'


/* Константы пермишинов */
export const CATEGORY_GET = 'CATEGORY_GET'
export const CATEGORY_CREATE = 'CATEGORY_CREATE'
export const CATEGORY_UPDATE = 'CATEGORY_UPDATE'
export const CATEGORY_REMOVE = 'CATEGORY_REMOVE'

/* Константы для главного сайта */

export const SITE_CATEGORY = 'SITE_CATEGORY'
export const SITE_ARTICLES = 'SITE_ARTICLES'
export const NO_CATEGORY = 'no-category'
export const COUNT_SEARCH = 'COUNT_SEARCH'
export const SITE_ADS = 'SITE_ADS'

/* Константы категорий */

export const FACEBOOK_ID = 45
export const PHOTO_VIDEO = 44
export const THINK_LOUD = 43
export const INTERVIEW = 42
export const NETWORK_SAYS = 70
export const POLITIC_ID = 47
export const PUBLISH_ID = 41
export const DIGEST = 82

/* Translations */

export const ALL_NEWS_RU = 'все новости'
export const ALL_NEWS_UA = 'всі новини'
export const YESTERDAT_RU = 'Вчера'
export const YESTERDAT_UA = 'Вчора'
export const ALL_PUBLISH_RU = 'Все публикации'
export const ALL_PUBLISH_UA = 'Всі публікації'
export const WITHOUR_CATEGORY_RU = 'Без категории'
export const WITHOUR_CATEGORY_UA = 'Без категорії'
export const EMAIL_RED_RU = 'email редакции: '
export const EMAIL_RED_UA = 'email редакції: '
export const RULES_SITE_RU = 'Правила пользования сайтом'
export const RULES_SITE_UA = 'Правила користування сайтом'
export const SECURE_RIGHTS_RU = 'Все права защищены'
export const SECURE_RIGHTS_UA = 'Всі права захищені'
export const SEARCH_RU = 'Поиск...'
export const SEARCH_UA = 'Пошук...'

export const seo_categories = [{
    id: 40,
    seo_title: 'Главные новости дня. Последние новости страны и мира.',
    seo_description: 'Актуальные новости и события дня в мире политики, экономики, сообщества. Главные события в Украине, самые свежие новости со всего мира. Публикации, фото, видео.',
    seo_keywords: 'главные новости, главные события, последние новости, новости дня, события в Украине, новости мира, новости политики, новости экономики',
},{
    id: 41,
    seo_title: 'Публикации на темы политики, экономики, общества на портале.',
    seo_description: 'Горячие темы, статьи и публикации, свежий взгляд на  темы политики, экономики, общества; рубрики: Иностранные СМИ, Не то АТО, Портрет, Без политики на нашем информационном портале.',
    seo_keywords: 'статьи, публикации, иносми, не то АТО, без политики'
},{
    id: 42,
    seo_title: 'Интервью | Общество и люди. Истории и статьи из жизни людей.',
    seo_description: 'Как живут люди в Украине. Мысли, мнения, новости и интересные истории, рассказы интересных людей на нашем информационном портале.',
    seo_keywords: 'общество, люди, мысли, мнения, новости, истории, рассказы'
},{
    id: 43,
    seo_title: 'Мысли вслух | Мнения, высказывания, интересные мысли.',
    seo_description: 'Высказывания, мнения политиков и общественных деятелей по актуальным вопросам политики, экономики, сообщества. Свежий взгляд, интересные мысли на нашем портале.',
    seo_keywords: 'высказывания, мнения, политика, экономика, сообщество'
},{
    id: 44,
    seo_title: 'Фото и видео  по темам  политики, экономики, сообщества.',
    seo_description: 'Для Вас фоторепортажи и видео новости по темам политики, экономики, сообщества. Фоторепортажи со знаменитостями, актуальные материалы о светских событиях.',
    seo_keywords: 'фото, видео, видео новости, новости политики, новости экономики'
},{
    id: 45,
    seo_title: 'Актуально о событиях в Украине и мире на страницах Фейсбук.',
    seo_description: 'В рубрике - говорит Facebook - для Вас статьи и публикации на актуальные темы дня в мире политики, экономики, общества, широко обсуждаемые на страницах Фейсбук.',
    seo_keywords: ''
},{
    id: 46,
    seo_title: 'Публикации на тему экономики и актуальная информация на портале.',
    seo_description: 'Публикации на экономические темы Украины и мира. Экономические новости Украины, мировая экономика и многое другое. Новости экономики и самая актуальная информация на портале. Фото, видео.',
    seo_keywords: 'новости экономики, экономические новости, свежие новости'
},{
    id: 47,
    seo_title: 'Публикации на тему политики и актуальная информация на портале.',
    seo_description: 'Свежий взгляд, горячие темы, интересные статьи, публикации и аналитика на политические темы Украины и мира на нашем информационном портале.',
    seo_keywords: 'свежий взгляд, горячие темы, статьи, публикации, политические темы Украины и мира '
},{
    id: 55,
    seo_title: 'Иностранные СМИ. Новости, статьи, публикации на портале.',
    seo_description: 'Актуальные новости и события дня в мире политики, экономики, сообщества. Новости, статьи и публикации ведущих зарубежных СМИ.',
    seo_keywords: 'актуальные новости, последние новости,  новости политики, новости экономики, иностранные СМИ, зарубежные СМИ'
},{
    id: 57,
    seo_title: 'Портрет | Статьи и публикации о судьбах людей.',
    seo_description: 'Читайте самые интересные истории из жизни людей. Публикации и статьи о судьбах, жизни, делах людей на нашем информационном портале.                       ',
    seo_keywords: 'статьи, публикации, интересные истории, люди'
},{
    id: 58,
    seo_title: 'Общественные события в Украине и в мире на нашем портале. ',
    seo_description: 'Свежие новости о событиях в обществе. Статьи и публикации на тему: культура, искусство, общественные новости. Общественные события Украины и мира.',
    seo_keywords: 'общественные события, события Украины, события мира, статьи, публикации, свежие новости'
},{
    id: 59,
    seo_title: 'Вне политики | Статьи и публикации, мнения на портале.',
    seo_description: 'Читайте интересные и актуальные публикации и статьи на внеполитические темы на нашем информационном портале.',
    seo_keywords: 'статьи, публикации, вне политики'
},{
    id: 79,
    seo_title: 'Не то АТО. Обзор потерь в зоне военных действий за 23 - 29 мая | Новости - МИР',
    seo_description: 'Уважаемые читатели, мы открываем новую рубрику «Не то АТО». В ней мы будем публиковать правдивые отчеты о событиях, происшедших в зоне АТО за прошедшую неделю. Подчеркиваем – максимально объективные отчеты, полученные от людей, которые, в том числе, находятся на фронте, а не только от «залакированных» пресс-офицеров, которые и информацию подают такую же «припудренную»" ',
    seo_keywords: 'Донбасс, АТО, Луганск, война" '
},{
    id: 81,
    seo_title: 'Дневник редакции | Новости, статьи, публикации на портале.',
    seo_description: 'В рубрике: Дневник редакции читайте статьи и публикации, мнения и свежий взгляд от редакции нашего информационного портала.',
    seo_keywords: 'новости, статьи, публикации, дневник редакции'
},{
    id: 82,
    seo_title: 'Дайджест новостей | Коротко обо всем на портале.',
    seo_description: 'Для Вас наиболее интересные материалы на  темы политики, экономики, общества, появившиеся в печати в последнее время на нашем портале.',
    seo_keywords: 'новости, статьи, публикации, дневник редакции'
}

]

